<template>
	<div class="row" v-if="!isActiveMobilMenu">
		<h5 class="fw-bold text-center pt-2 mb-0 border-bottom border-1 border-secondary-subtle text-black">МЕНЮ</h5>
		<div class="col-6 col-sm-6 col-lg-3 col-md-4 col-xl-3 mt-3 px-2 d-flex justify-content-evenly"
			v-for="(menu, index) of getMenus" :key="index">
			<router-link :to="'/products/' + menu.id">
				<div class="card border-0">
					<div class="imgContainer position-relative">
					<span class="position-absolute menu-name">
						{{menu.name}}
					</span>
						<img class="menu-img" :src="url + menu.image.filePath" alt="img"/>
					</div>
					<div class="card-body px-2 py-0 text-center">
					</div>
				</div>
			</router-link>
		</div>
	</div>
	<div class="row" v-if="isActiveMobilMenu">
		<h5 class="fw-bold text-center mb-0">МЕНЮ</h5>
		<div
			class="col-6 col-sm-6 col-lg-3 col-md-4 col-xl-3 mt-3 px-2 d-flex justify-content-evenly"
			data-bs-dismiss="offcanvas"
			aria-label="Close"
			v-for="(menu, index) of getMenus" :key="index">
			<router-link :to="'/products/' + menu.id">
				<div class="card border-0">
					<div class="imgContainer position-relative">
					<span class="position-absolute menu-name menu-name-mobile">
						{{menu.name}}
					</span>
						<img class="menu-img" :src="url + menu.image.filePath" alt="img"/>
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default{
	name: "MenusComponent",
	props: {
		isActiveMobilMenu: {
			type: Boolean,
		},
	},
	data() {
		return {
			url: process.env.VUE_APP_API_URL + '/media/'
		}
	},
	computed: {
		...mapGetters(['getMenus'])
	},
	methods: {
		...mapActions(['fetchMenus'])
	},
	mounted() {
		this.fetchMenus()
	}
}
</script>

<style scoped>
.menu-name {
    bottom: 0;
    left: 22px;
    font-weight: bolder;
    color: white;
    font-size: 15px;
    z-index: 1;
}

.menu-img {
    width: 100%;
    height: 100%;
	border-radius: 0 30px;
	border-bottom: #fe0000 solid 25px;
	background-color: #ededed;
    transition: transform .2s
}

.menu-img:hover {
    box-shadow: 2px 2px 8px #5c5b5b;
	background-color: #ededed;
    transform: scale(102%);
}

@media (min-width: 800px) {
    .menu-img {
        border-radius: 0 40px;
        border-bottom: #fe0000 solid 30px;

    }

    .menu-name {
        bottom: -5px;
        left: 27px;
        font-size: 25px;
    }
}
</style>