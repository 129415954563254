<template>
	<footer class="text-center text-lg-start bg-body-tertiary text-muted mt-3">
		<!-- Section: Social media -->
		<section class="d-flex p-2 border-bottom media">
			<div class="me-5 d-none d-sm-block">
				<span>Следуйте за нами в социальных сетях:</span>
			</div>
			<div class="me-5 d-sm-none">
				<span>В сетях:</span>
			</div>
			<div class="ms-auto">
				<a href="https://t.me/+79247831990">
					<img width="25" src="../assets/telegram-icon.png" alt="Telegram"/>
				</a>
				<a href="https://api.whatsapp.com/send/?phone=79631420505&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21%0A%0A%D0%9F%D0%B8%D1%88%D1%83+%D0%B8%D0%B7+%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F+2%D0%93%D0%98%D0%A1.%0A%0A&type=phone_number&app_absent=0" class="mx-2">
					<img width="26" src="../assets/whatsaap-icon.png" alt="Whatsapp"/>
				</a>
				<a href="#">
					<img width="25" src="../assets/instagramm-icon.png" alt="Instagram"/>
				</a>
			</div>
		</section>
		<!-- Section: Social media -->

		<!-- Section: Links  -->
		<section>
			<div class="text-start">
				<div class="row mt-3">
					<div class="col-12 col-md-6 ps-2">
						<div>
							<a href="tel:+79631420505" class="fw-bold mb-0 text-black links">
								Телефон доставки:
							</a>
							<a href="tel:+79631420505" class="text-reset links">
                                +7-963-142-05-05
							</a>
						</div>
						<router-link to="/aboutUs" class="d-block text-decoration-none">
							<span class="fw-bold text-black links">
                                О нас
                            </span>
						</router-link>
						<router-link to="/help" class="text-decoration-none">
							<span class="fw-bold text-black links">
                                Помощь
                            </span>
						</router-link>
					</div>
					<div class="col-12 col-sm-6 mt-2 text-black  ps-2">
						<div>
                            <router-link to="/" class="fw-bold mb-0 text-black links">
                                Chayxana41
                            </router-link>
						</div>
						<p>
                            Кафе Чайхана41 осуществляет доставку по всему Петропавловску-Камчатскому.
						</p>
					</div>
				</div>
			</div>
		</section>
		<div class="text-center p-2 media">
			© 2024-02-21 :
			<router-link class="text-reset fw-bold" to="/">Chayxana41.ru</router-link>
		</div>
	</footer>
</template>
<script>
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Footer"
}
</script>

<style scoped>
.media {
    background:#fe0000;
	color: white;
}

.links {
	color: #fe0000;
    text-decoration: none;
    cursor: pointer;
}

.links:hover {
	color: #fe0000 !important;
}
</style>