<template>
	<div class="px-0">
		<h5 class="fw-bold pt-2 mt-4 d-sm-block border-bottom border-2 border-black">ОФОРМИТЬ ЗАКАЗ</h5>
		<form>
            <div class="mt-3">
                <b>Бесплатно при заказе от: </b>
                <b style="color: red">  1.000 </b>
                <img  width="12" class="mb-1	" src="../assets/pngwing.com.png" alt="P">
            </div>
            <div class="my-1">
                <b>Доставка:</b>
                <b
                    v-if="offer.totalPrice >= 1000"
                    class="offer-free"
                >
                    Бесплатно!
                </b>
                <b
                    v-if="offer.totalPrice < 1000"
                    style="color: red"
                >
                    200
                </b>
                <img
                    v-if="offer.totalPrice < 1000"
                    width="12"
                    class="mb-1"
                    src="../assets/pngwing.com.png" alt="P"
                >
            </div>
            <div class="mb-2">
                <b>Итого: </b>
                <b style="color: red; margin-right: 3px">  {{ offer.totalPrice.toLocaleString('de-DE') }} </b>
                <img  width="12" class="mb-1	" src="../assets/pngwing.com.png" alt="P">
            </div>
			<div>
				<span class="warning-send-element mb-2">
					Внимание! заказы доставляются только в П-Камчатский.
				</span>
			</div>
			<div class="row">
				<div class="col-sm-6 col-lg-3 mb-3 ps-0">
					<label for="validationCustom01" class="mb-0" ref="form-label-name">Имя:</label>
					<input
						type="text"
						class="form-control"
						ref="form-control-name"
						id="validationCustom01"
						v-model="offer.name">
				</div>
				<div class="col-sm-6 col-lg-3 mb-3 ps-0">
					<label for="validationCustom01" class="mb-0" ref="form-label-telephone">Телефон:</label>
					<input
						type="tel"
						class="form-control"
						ref="form-control-tel"
						id="validationCustom01"
						v-model="offer.telephone">
				</div>
                <div class="col-sm-6 col-lg-3 mb-3 ps-0">
                    <label for="exampleFormControlTextarea1" class="form-label mb-0">Комментарий:</label>
                    <textarea class="form-control"
                              placeholder="Не обязательно"
                              id="exampleFormControlTextarea1"
                              rows="1"
                              v-model="offer.customerComment"
                    ></textarea>
                </div>
                <div class="col-sm-6 col-lg-3 mb-3 ps-0">
                    <label for="validationCustom01" class="form-label mb-0">Адрес:</label>
                    <input
                            type="text"
                            class="form-control"
                            ref="form-control-address"
                            id="validationCustom01"
                            v-model="offer.address">
                </div>
            </div>
            <div class="mb-5">
                <div>
                    <span :class="offer.name && offer.telephone && offer.address === '' || telPattern ? 'warning-send-element mb-2' : 'd-none'">
                        Убедитесь, что все строки заполнены!
                    </span>
                </div>
                <button
                        @click="sendOrderFun"
                        type="button"
                        class="btn btn-outline-primary btn-sm fw-bold send-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        :disabled="offer.name && offer.telephone && offer.address === '' || telPattern"
                >
                    ЗАКАЗАТЬ
                </button>
            </div>
		</form>
	</div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
	name: "OrderSendComponent",
	data() {
		return {
			offer: {
                name: '',
                telephone: '',
                address: '',
                totalPrice: 0,
                customerComment: ''
            },
            telPattern: true
		}
	},
	computed: {
		...mapState(['cartProducts'])
	},
	methods: {
        ...mapActions(['sendOrder']),
		sendOrderFun() {
            let order = {
                name: this.offer.name,
                telephone: this.offer.telephone,
                address: this.offer.address,
                createdAt: new Date().toISOString(),
                totalPrice: this.offer.totalPrice,
                customerComment: this.offer.customerComment,
                orders: []
            };
            this.cartProducts.forEach((item) => {
                order.orders.push(JSON.stringify({
                    name: item.name,
                    price: item.price,
                    weight: item.weight,
                    count: item.count
                }))
            })
            this.sendOrder(order)
        }
	},
	mounted() {
		// Karta Ma'lumotlar'
		// axios.get('https://ipapi.co/json')
		// 	.then((res) => {
		// 		console.log(res.data.city)
		// 	})
	},

	watch: {
        'offer.name'(value) {
			if (value !== '') {
				this.$refs["form-control-name"].style.borderColor = 'green'
				this.$refs["form-label-name"].style.color = 'green'
			}
			else {
				this.$refs["form-control-name"].style.borderColor = 'red'
				this.$refs["form-label-name"].style.color = 'red'
			}
		},
		'offer.telephone'(value) {
			let pattern = /^(\+7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/
			if (pattern.test(value)) {
				this.$refs["form-control-tel"].style.borderColor = 'green'
				this.$refs["form-label-telephone"].style.color = 'green'
                this.telPattern = false
			}
			else {
				this.$refs["form-control-tel"].style.borderColor = 'red'
				this.$refs["form-label-telephone"].style.color = 'red'
                this.telPattern = true
			}
		},
		cartProducts: {
			handler: function () {
				let newTotalPrice = 0;
				this.cartProducts.forEach((product) => {
					newTotalPrice += product.price * product.count;
				});

				if (newTotalPrice < 1000 && this.cartProducts.length) {
					newTotalPrice += 200;
				}

				this.offer.totalPrice = newTotalPrice;
			},
			deep: true,
			immediate: true
		},
	},
}
</script>

<style scoped>
.form-control {
	box-shadow: 0 0 0;
}

.offer-free {
    color: #27b027;
}

.warning-send-element {
	display: block;
	color: red;
	font-weight: 550;
    font-size: 18px;
}

.send-btn {
    width: 150px;
}
</style>