import postRequest from "@/plugins/requests/postRequest";
export default {
    actions: {
        sendMessageAction(context, data) {
            return postRequest('/messages', data, 'updateMessageSend', context)
        },
    },

    mutations: {
        updateMessageSend() {}
    },

    state: {
        messages: {
            models: [],
            totalItems:0
        }
    },
}
